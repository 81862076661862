import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faClock, faEnvelope, faEye, faLink, faLocationDot, faMapMarkerAlt, faPhone, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Breadcrumb, Button, Col, Container, Nav, Navbar, Row } from 'react-bootstrap'

function PortfolioPage() {
    return (
        <div>

            <Container fluid className="bg-light p-0">
                <Row className="gx-0 d-none d-lg-flex">
                    <Col lg={7} className="px-5 text-start">
                        <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="text-primary me-2" />
                            <small>Köprübaşı Mah. Aralık Cad. No:74/1 Serdivan</small>
                        </div>
                        <div className="h-100 d-inline-flex align-items-center py-3">
                            <FontAwesomeIcon icon={faClock} className="text-primary me-2" />
                            <small>P.tesi - Cuma : 09.00 - 18.00</small>
                        </div>
                    </Col>
                    <Col lg={5} className="px-5 text-end">
                        <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                            <FontAwesomeIcon icon={faPhoneAlt} className="text-primary me-2" />
                            <small>0 532 557 9054</small>
                        </div>
                        <div className="h-100 d-inline-flex align-items-center">
                            <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faFacebookF} /></a>
                            <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faTwitter} /></a>
                            <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                            <a className="btn btn-sm-square bg-white text-primary me-0" href="/"><FontAwesomeIcon icon={faInstagram} /></a>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Navbar bg="white" expand="lg" sticky="top" className="p-0">
                <Navbar.Brand href="index.html" className="d-flex align-items-center px-4 px-lg-5">
                    <h2 className="m-0 text-primary">KOÇ YAPI İNŞAAT</h2>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarCollapse" className="me-4" />
                <Navbar.Collapse id="navbarCollapse">
                    <Nav className="ms-auto p-4 p-lg-0">
                        <Nav.Link href="/" className="nav-item nav-link active">AnaSayfa</Nav.Link>
                        <Nav.Link href="/about" className="nav-item nav-link">Hakkımızda</Nav.Link>
                        <Nav.Link href="/services" className="nav-item nav-link">Hizmetlerimiz</Nav.Link>
                        <Nav.Link href="/portfolio" className="nav-item nav-link">Projeler</Nav.Link>
                        {/* <NavDropdown title="Pages" id="basic-nav-dropdown">
                            <NavDropdown.Item href="feature.html">Feature</NavDropdown.Item>
                            <NavDropdown.Item href="quote.html">Free Quote</NavDropdown.Item>
                            <NavDropdown.Item href="team.html">Our Team</NavDropdown.Item>
                            <NavDropdown.Item href="testimonial.html">Testimonial</NavDropdown.Item>
                            <NavDropdown.Item href="404.html">404 Page</NavDropdown.Item>
                        </NavDropdown> */}
                        <Nav.Link href="/contact" className="nav-item nav-link">İletişim</Nav.Link>
                    </Nav>
                    <Button href="https://wa.me/905325579054" className="btn btn-primary py-4 px-lg-5 d-none d-lg-block">Teklif Al<i className="fa fa-arrow-right ms-3"></i></Button>
                </Navbar.Collapse>
            </Navbar>

            <div className="container-fluid page-header py-5 mb-5">
                <Container className="py-5">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Projeler</h1>
                    <Breadcrumb className="animated slideInDown">
                        <Breadcrumb.Item href="/" className="text-white">AnaSayfa</Breadcrumb.Item>
                        <Breadcrumb.Item active className="text-white">Projeler</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
            </div>

            <div className="py-5">
                <Container>
                    <div className="text-center mb-5">
                        <h1 className="display-5 mb-5">Projeler</h1>
                    </div>
                    <Row className="g-4">
                        <Col lg={4} md={6} className="portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
                            <div className="rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid w-100" src="/project-1.jpeg" alt="" />
                                    <div className="portfolio-overlay">
                                        <Button variant="outline-light" className="btn-square mx-1" href="/" data-lightbox="portfolio">
                                            <FontAwesomeIcon icon={faEye} />
                                        </Button>
                                        <Button variant="outline-light" className="btn-square mx-1" href="/">
                                            <FontAwesomeIcon icon={faLink} />
                                        </Button>
                                    </div>
                                </div>
                                <div className="border border-5 border-light border-top-0 p-4">
                                    <p className="text-primary fw-medium mb-2">Çelik Villa</p>
                                    <h5 className="lh-base mb-0">200 m2 Çelik Villa Projemiz</h5>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
                            <div className="rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid w-100" src="/project-2.jpeg" alt="" />
                                    <div className="portfolio-overlay">
                                        <Button variant="outline-light" className="btn-square mx-1" href="/" data-lightbox="portfolio">
                                            <FontAwesomeIcon icon={faEye} />
                                        </Button>
                                        <Button variant="outline-light" className="btn-square mx-1" href="/">
                                            <FontAwesomeIcon icon={faLink} />
                                        </Button>
                                    </div>
                                </div>
                                <div className="border border-5 border-light border-top-0 p-4">
                                    <p className="text-primary fw-medium mb-2">Çelik Villa</p>
                                    <h5 className="lh-base mb-0">Teraslı Çelik Villa Projemiz</h5>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
                            <div className="rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid w-100" src="/project-3.jpeg" alt="" />
                                    <div className="portfolio-overlay">
                                        <Button variant="outline-light" className="btn-square mx-1" href="/" data-lightbox="portfolio">
                                            <FontAwesomeIcon icon={faEye} />
                                        </Button>
                                        <Button variant="outline-light" className="btn-square mx-1" href="/">
                                            <FontAwesomeIcon icon={faLink} />
                                        </Button>
                                    </div>
                                </div>
                                <div className="border border-5 border-light border-top-0 p-4">
                                    <p className="text-primary fw-medium mb-2">Tiny House</p>
                                    <h5 className="lh-base mb-0">Tiny House Projemiz</h5>
                                </div>
                            </div>
                        </Col>
                        {/* Diğer portföy öğeleri buraya eklenecek */}
                    </Row>
                </Container>
            </div>



            <div className="container-fluid bg-dark text-light footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
                <Container>
                    <Row className="g-5 pb-5">
                        <Col lg={3} md={6}>
                            <h4 className="text-light mb-4">Adres</h4>
                            <p className="mb-2"><FontAwesomeIcon icon={faLocationDot} className="mx-3" />Köprübaşı Mah. Aralık Cad. No:74/1 Serdivan</p>
                            <p className="mb-2"><FontAwesomeIcon icon={faPhone} className="mx-3" />0 532 557 9054</p>
                            <p className="mb-2"><FontAwesomeIcon icon={faEnvelope} className="mx-3" />info@kocyapi.com</p>
                            <div className="d-flex pt-2">
                                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faInstagram} className="mx-3" /></Button>
                                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faFacebookF} className="mx-3" /></Button>
                                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faTwitter} className="mx-3" /></Button>
                                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faYoutube} className="mx-3" /></Button>
                            </div>
                        </Col>
                        {/* Remaining columns */}
                    </Row>
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; <a className="border-bottom" href="#">@kocyapi</a>, All Right Reserved.
                            </div>
                            <div className="col-md-6 text-center text-md-end">
                                Designed By <a className="border-bottom" href="https://htmlcodex.com">Koç Yapı</a>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default PortfolioPage