import React, { useState } from 'react'
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faChevronLeft, faChevronRight, faClock, faEnvelope, faLocationDot, faMapMarkerAlt, faPhone, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb, Button, Col, Container, Nav, Navbar, Row } from 'react-bootstrap'

function ServicesPage() {

    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? testimonials.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const goToNext = () => {
        const isLastSlide = currentIndex === testimonials.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };
    return (
        <div>

            <Container fluid className="bg-light p-0">
                <Row className="gx-0 d-none d-lg-flex">
                    <Col lg={7} className="px-5 text-start">
                        <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="text-primary me-2" />
                            <small>Köprübaşı Mah. Aralık Cad. No:74/1 Serdivan</small>
                        </div>
                        <div className="h-100 d-inline-flex align-items-center py-3">
                            <FontAwesomeIcon icon={faClock} className="text-primary me-2" />
                            <small>P.tesi - Cuma : 09.00 - 18.00</small>
                        </div>
                    </Col>
                    <Col lg={5} className="px-5 text-end">
                        <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                            <FontAwesomeIcon icon={faPhoneAlt} className="text-primary me-2" />
                            <small>0 532 557 9054</small>
                        </div>
                        <div className="h-100 d-inline-flex align-items-center">
                            <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faFacebookF} /></a>
                            <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faTwitter} /></a>
                            <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                            <a className="btn btn-sm-square bg-white text-primary me-0" href="/"><FontAwesomeIcon icon={faInstagram} /></a>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Navbar bg="white" expand="lg" sticky="top" className="p-0">
                <Navbar.Brand href="index.html" className="d-flex align-items-center px-4 px-lg-5">
                    <h2 className="m-0 text-primary">KOÇ YAPI İNŞAAT</h2>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarCollapse" className="me-4" />
                <Navbar.Collapse id="navbarCollapse">
                    <Nav className="ms-auto p-4 p-lg-0">
                        <Nav.Link href="/" className="nav-item nav-link active">AnaSayfa</Nav.Link>
                        <Nav.Link href="/about" className="nav-item nav-link">Hakkımızda</Nav.Link>
                        <Nav.Link href="/services" className="nav-item nav-link">Hizmetlerimiz</Nav.Link>
                        <Nav.Link href="/portfolio" className="nav-item nav-link">Projeler</Nav.Link>
                        {/* <NavDropdown title="Pages" id="basic-nav-dropdown">
                            <NavDropdown.Item href="feature.html">Feature</NavDropdown.Item>
                            <NavDropdown.Item href="quote.html">Free Quote</NavDropdown.Item>
                            <NavDropdown.Item href="team.html">Our Team</NavDropdown.Item>
                            <NavDropdown.Item href="testimonial.html">Testimonial</NavDropdown.Item>
                            <NavDropdown.Item href="404.html">404 Page</NavDropdown.Item>
                        </NavDropdown> */}
                        <Nav.Link href="/contact" className="nav-item nav-link">İletişim</Nav.Link>
                    </Nav>
                    <Button href="https://wa.me/905325579054" className="btn btn-primary py-4 px-lg-5 d-none d-lg-block">Teklif Al<i className="fa fa-arrow-right ms-3"></i></Button>
                </Navbar.Collapse>
            </Navbar>
            <div className="container-fluid page-header py-5 mb-5">
                <Container className="py-5">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Hizmetlerimiz</h1>
                    <Breadcrumb className="animated slideInDown">
                        <Breadcrumb.Item href="/" className="text-white">AnaSayfa</Breadcrumb.Item>
                        <Breadcrumb.Item active className="text-white">Hizmetlerimiz</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
            </div>


            <div className="container-xxl py-5">
                <Container>
                    <div className="section-title text-center">
                        <h1 className="display-5 mb-5">Hizmetlerimiz</h1>
                    </div>
                    <Row className="g-4">
                        <Col md={6} lg={4} className="wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src="/service-1.jpg" alt="" />
                                </div>
                                <div className="p-4 text-center border border-5 border-light border-top-0">
                                    <h4 className="mb-3">Çelik Ev</h4>
                                    <p>Güvenli ve şık tasarımıyla çelik evlerimizde sizlere sıcak yuvalar kuruyoruz.</p>
                                    <Button href='https://wa.me/905325579054' variant="primary" className="fw-medium">İletişim<i className="fa fa-arrow-right ms-2"></i></Button>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4} className="wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src="/service-2.jpg" alt="" />
                                </div>
                                <div className="p-4 text-center border border-5 border-light border-top-0">
                                    <h4 className="mb-3">Çelik Villa</h4>
                                    <p>Kaliteli malzeme kullanımı ve sizin talepleriniz doğrultusunda şık tasarım villalarımız.</p>
                                    <Button href='https://wa.me/905325579054' variant="primary" className="fw-medium">İletişim<i className="fa fa-arrow-right ms-2"></i></Button>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4} className="wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src="/service-3.jpg" alt="" />
                                </div>
                                <div className="p-4 text-center border border-5 border-light border-top-0">
                                    <h4 className="mb-3">Tiny House</h4>
                                    <p>Gösterişli tasarımlarla sizlere Ev konseptinde Tiny House lar sunuyoruz .</p>
                                    <Button href='https://wa.me/905325579054' variant="primary" className="fw-medium">İletişim<i className="fa fa-arrow-right ms-2"></i></Button>
                                </div>
                            </div>
                        </Col>
                        {/* <Col md={6} lg={4} className="wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src="/service-4.jpg" alt="" />
                                </div>
                                <div className="p-4 text-center border border-5 border-light border-top-0">
                                    <h4 className="mb-3">Wooden Floor</h4>
                                    <p>Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem diam.</p>
                                    <Button variant="primary" className="fw-medium">Read More<i className="fa fa-arrow-right ms-2"></i></Button>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4} className="wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src="/service-5.jpg" alt="" />
                                </div>
                                <div className="p-4 text-center border border-5 border-light border-top-0">
                                    <h4 className="mb-3">Wooden Furniture</h4>
                                    <p>Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem diam.</p>
                                    <Button variant="primary" className="fw-medium">Read More<i className="fa fa-arrow-right ms-2"></i></Button>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4} className="wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src="/service-6.jpg" alt="" />
                                </div>
                                <div className="p-4 text-center border border-5 border-light border-top-0">
                                    <h4 className="mb-3">Custom Work</h4>
                                    <p>Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem diam.</p>
                                    <Button variant="primary" className="fw-medium">Read More<i className="fa fa-arrow-right ms-2"></i></Button>
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                </Container>
            </div>

            <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
                <Container>
                    <div className="section-title text-center">
                        <h1 className="display-5 mb-5">Mutlu Müşteriler</h1>
                    </div>
                    <div className="testimonial-slider">
                        <div className="img-container">
                            <div className="testimonial-item text-center ">
                                <img className="img-fluid bg-light p-2 mx-auto mb-3" src={`/testimonial-${currentIndex + 1}.jpg`} style={{ width: '90px', height: '90px' }} alt="Testimonial" />
                                <button className="arrow left-arrow" onClick={goToPrevious}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                                <button className="arrow right-arrow" onClick={goToNext}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </div>
                            <div className="testimonial-text text-center p-4">
                                <p>{testimonials[currentIndex].text}</p>
                                <h5 className="mb-1">{testimonials[currentIndex].client}</h5>
                                <span className="fst-italic">{testimonials[currentIndex].profession}</span>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>



            <div className="container-fluid bg-dark text-light footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
                <Container>
                    <Row className="g-5 pb-5">
                        <Col lg={3} md={6}>
                            <h4 className="text-light mb-4">Adres</h4>
                            <p className="mb-2"><FontAwesomeIcon icon={faLocationDot} className="mx-3" />Köprübaşı Mah. Aralık Cad. No:74/1 Serdivan</p>
                            <p className="mb-2"><FontAwesomeIcon icon={faPhone} className="mx-3" />0 532 557 9054</p>
                            <p className="mb-2"><FontAwesomeIcon icon={faEnvelope} className="mx-3" />info@kocyapi.com</p>
                            <div className="d-flex pt-2">
                                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faInstagram} className="mx-3" /></Button>
                                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faFacebookF} className="mx-3" /></Button>
                                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faTwitter} className="mx-3" /></Button>
                                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faYoutube} className="mx-3" /></Button>
                            </div>
                        </Col>
                        {/* Remaining columns */}
                    </Row>
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; <a className="border-bottom" href="#">@kocyapi</a>, All Right Reserved.
                            </div>
                            <div className="col-md-6 text-center text-md-end">
                                Designed By <a className="border-bottom" href="https://htmlcodex.com">Koç Yapı</a>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

const testimonials = [
    { text: 'Koç Yapı inşaatın başlangıcından sonuna kadar her işin arkasında duran ve tüm sorunları karşılıklı olarak konuşup çözüme kavuşturabilieceğiniz harika bir firma. Ev yapmanın zorluklarının üstesinden dinamik ve kaliteli kadrosuyla gelebilmektedir. Siz sadece arkanıza yaslanın ve anın tadını çıkarın. Onlar sizin yerinize herşeyi düşünüyor.', client: 'Melih GÖKDENİZ', profession: 'Müşteri' },
    { text: 'Öncelikle Koç Yapı ya sonsuz teşekkürler. Yaptıkları her işi mükemmel yapabilme gibi bir özellikleri var. Tüm sorularımıza cevap verip bizi dikkatle dinlemeleri bu zamanda zor bulunan bir durum. Onlara tekrar tekrar teşekkür ediyorum.', client: 'Yücel DEMİRÖZ', profession: 'Müşteri' },
    { text: 'Ev yapma sürecini daha önce tecribe edenler bir ev yapmanın ne kadar zor olduğunu bilirler. Ama bu durum Koç Yapı ile tanışınca son buluyor. Gerçekten işlerini düzgün yapan kaliteli bir kadroları var. Üstüne üstlük sizlerle iletişim kurup sorunlarınız çözebiliyorlar. Çok teşekkür ederiz.', client: 'Buse YILMAZER TUNCA', profession: 'Müşteri' }
];

export default ServicesPage