import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faClock, faEnvelope, faLocationDot, faMapMarkerAlt, faPhone, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Breadcrumb, Button, Col, Container, Form, Nav, Navbar, Row } from 'react-bootstrap'

function ContactPage() {
  return (
    <div>
      <Container fluid className="bg-light p-0">
        <Row className="gx-0 d-none d-lg-flex">
          <Col lg={7} className="px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center py-3 me-4">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="text-primary me-2" />
              <small>Köprübaşı Mah. Aralık Cad. No:74/1 Serdivan</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center py-3">
              <FontAwesomeIcon icon={faClock} className="text-primary me-2" />
              <small>P.tesi - Cuma : 09.00 - 18.00</small>
            </div>
          </Col>
          <Col lg={5} className="px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center py-3 me-4">
              <FontAwesomeIcon icon={faPhoneAlt} className="text-primary me-2" />
              <small>0 532 557 9054</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center">
              <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faFacebookF} /></a>
              <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faTwitter} /></a>
              <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faLinkedinIn} /></a>
              <a className="btn btn-sm-square bg-white text-primary me-0" href="/"><FontAwesomeIcon icon={faInstagram} /></a>
            </div>
          </Col>
        </Row>
      </Container>

      <Navbar bg="white" expand="lg" sticky="top" className="p-0">
        <Navbar.Brand href="index.html" className="d-flex align-items-center px-4 px-lg-5">
          <h2 className="m-0 text-primary">KOÇ YAPI İNŞAAT</h2>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarCollapse" className="me-4" />
        <Navbar.Collapse id="navbarCollapse">
          <Nav className="ms-auto p-4 p-lg-0">
            <Nav.Link href="/" className="nav-item nav-link active">AnaSayfa</Nav.Link>
            <Nav.Link href="/about" className="nav-item nav-link">Hakkımızda</Nav.Link>
            <Nav.Link href="/services" className="nav-item nav-link">Hizmetlerimiz</Nav.Link>
            <Nav.Link href="/portfolio" className="nav-item nav-link">Projeler</Nav.Link>
            {/* <NavDropdown title="Pages" id="basic-nav-dropdown">
                            <NavDropdown.Item href="feature.html">Feature</NavDropdown.Item>
                            <NavDropdown.Item href="quote.html">Free Quote</NavDropdown.Item>
                            <NavDropdown.Item href="team.html">Our Team</NavDropdown.Item>
                            <NavDropdown.Item href="testimonial.html">Testimonial</NavDropdown.Item>
                            <NavDropdown.Item href="404.html">404 Page</NavDropdown.Item>
                        </NavDropdown> */}
            <Nav.Link href="/contact" className="nav-item nav-link">İletişim</Nav.Link>
          </Nav>
          <Button href="https://wa.me/905325579054" className="btn btn-primary py-4 px-lg-5 d-none d-lg-block">Teklif Al<i className="fa fa-arrow-right ms-3"></i></Button>
        </Navbar.Collapse>
      </Navbar>

      <div className="container-fluid page-header py-5 mb-5">
        <Container className="py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">İletişim</h1>
          <Breadcrumb className="animated slideInDown">
            <Breadcrumb.Item href="/" className="text-white">AnaSayfa</Breadcrumb.Item>
            <Breadcrumb.Item active className="text-white">İletişim</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
      </div>
      {/* Page Header End */}

      {/* Contact Start */}
      <div className="container-fluid bg-light overflow-hidden px-5" style={{ margin: '6rem 0' }}>
        <Container className="contact px-lg-0">
          <Row className="g-0 mx-lg-0">
            <Col lg={6} className="contact-text py-5 wow fadeIn" data-wow-delay="0.5s">
              <div className="p-lg-5 ps-lg-0">
                <div className="section-title text-start">
                  <h1 className="display-5 mb-4">İletişim</h1>
                </div>
                <p className="mb-4">
                  Aradığınız tüm sorunların cevapları için bizimle iletişime geçebilirsiniz.
                </p>
                <Form>
                  <Row className="g-3">
                    <Col md={6}>
                      <Form.Group controlId="name">
                        <Form.Label>İsim</Form.Label>
                        <Form.Control type="text" placeholder="İsim" />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="email">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control type="email" placeholder="E-mail" />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group controlId="subject">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control type="text" placeholder="Subject" />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group controlId="message">
                        <Form.Label>Mesaj</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Mesajınız" />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Button className="btn btn-primary w-100 py-3" type="submit">Gönder</Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
            <Col lg={6} className="pe-lg-0" style={{ minHeight: '400px' }}>
              <div className="position-relative h-100">
                <iframe
                  className="position-absolute w-100 h-100"
                  style={{ objectFit: 'cover' }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3020.846459089625!2d30.35953658953934!3d40.78738955921911!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ccb2b517b09b91%3A0x8652ea725c7e2bf8!2zS8O2cHLDvGJhxZ_EsSwgQXJhbMSxayBDZC4gTm86NzQgRDoxLCA1NDEzMCBTZXJkaXZhbi9TYWthcnlhLCBUw7xya2l5ZQ!5e0!3m2!1sen!2sbd!4v1718002928729!5m2!1sen!2sbd"
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="container-fluid bg-dark text-light footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
        <Container>
          <Row className="g-5 pb-5">
            <Col lg={3} md={6}>
              <h4 className="text-light mb-4">Adres</h4>
              <p className="mb-2"><FontAwesomeIcon icon={faLocationDot} className="mx-3" />Köprübaşı Mah. Aralık Cad. No:74/1 Serdivan</p>
              <p className="mb-2"><FontAwesomeIcon icon={faPhone} className="mx-3" />0 532 557 9054</p>
              <p className="mb-2"><FontAwesomeIcon icon={faEnvelope} className="mx-3" />info@kocyapi.com</p>
              <div className="d-flex pt-2">
                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faInstagram} className="mx-3" /></Button>
                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faFacebookF} className="mx-3" /></Button>
                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faTwitter} className="mx-3" /></Button>
                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faYoutube} className="mx-3" /></Button>
              </div>
            </Col>
            {/* Remaining columns */}
          </Row>
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy; <a className="border-bottom" href="#">@kocyapi</a>, All Right Reserved.
              </div>
              <div className="col-md-6 text-center text-md-end">
                Designed By <a className="border-bottom" href="https://htmlcodex.com">Koç Yapı</a>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default ContactPage