import React from 'react'
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faCheck, faClock, faDraftingCompass, faEnvelope, faHeadphones, faLocationDot, faMapMarkerAlt, faPhone, faPhoneAlt, faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb, Button, Col, Container, Nav, Navbar, Row } from 'react-bootstrap'

function AboutPage() {
    return (
        <div>

            <Container fluid className="bg-light p-0">
                <Row className="gx-0 d-none d-lg-flex">
                    <Col lg={7} className="px-5 text-start">
                        <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="text-primary me-2" />
                            <small>Köprübaşı Mah. Aralık Cad. No:74/1 Serdivan</small>
                        </div>
                        <div className="h-100 d-inline-flex align-items-center py-3">
                            <FontAwesomeIcon icon={faClock} className="text-primary me-2" />
                            <small>P.tesi - Cuma : 09.00 - 18.00</small>
                        </div>
                    </Col>
                    <Col lg={5} className="px-5 text-end">
                        <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                            <FontAwesomeIcon icon={faPhoneAlt} className="text-primary me-2" />
                            <small>0 532 557 9054</small>
                        </div>
                        <div className="h-100 d-inline-flex align-items-center">
                            <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faFacebookF} /></a>
                            <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faTwitter} /></a>
                            <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                            <a className="btn btn-sm-square bg-white text-primary me-0" href="/"><FontAwesomeIcon icon={faInstagram} /></a>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Navbar bg="white" expand="lg" sticky="top" className="p-0">
                <Navbar.Brand href="index.html" className="d-flex align-items-center px-4 px-lg-5">
                    <h2 className="m-0 text-primary">KOÇ YAPI İNŞAAT</h2>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarCollapse" className="me-4" />
                <Navbar.Collapse id="navbarCollapse">
                    <Nav className="ms-auto p-4 p-lg-0">
                        <Nav.Link href="/" className="nav-item nav-link active">AnaSayfa</Nav.Link>
                        <Nav.Link href="/about" className="nav-item nav-link">Hakkımızda</Nav.Link>
                        <Nav.Link href="/services" className="nav-item nav-link">Hizmetlerimiz</Nav.Link>
                        <Nav.Link href="/portfolio" className="nav-item nav-link">Projeler</Nav.Link>
                        {/* <NavDropdown title="Pages" id="basic-nav-dropdown">
                            <NavDropdown.Item href="feature.html">Feature</NavDropdown.Item>
                            <NavDropdown.Item href="quote.html">Free Quote</NavDropdown.Item>
                            <NavDropdown.Item href="team.html">Our Team</NavDropdown.Item>
                            <NavDropdown.Item href="testimonial.html">Testimonial</NavDropdown.Item>
                            <NavDropdown.Item href="404.html">404 Page</NavDropdown.Item>
                        </NavDropdown> */}
                        <Nav.Link href="/contact" className="nav-item nav-link">İletişim</Nav.Link>
                    </Nav>
                    <Button href="https://wa.me/905325579054" className="btn btn-primary py-4 px-lg-5 d-none d-lg-block">Teklif Al<i className="fa fa-arrow-right ms-3"></i></Button>
                </Navbar.Collapse>
            </Navbar>
            <div className="container-fluid page-header py-5 mb-5">
                <Container className="py-5">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">Hakkımızda</h1>
                    <Breadcrumb className="animated slideInDown">
                        <Breadcrumb.Item href="/" className="text-white">AnaSayfa</Breadcrumb.Item>
                        <Breadcrumb.Item active className="text-white">Hakkımızda</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
            </div>

            <div className="container-xxl py-5">
                <Container>
                    <Row className="g-5">
                        <Col md={6} lg={3} className="wow fadeIn" data-wow-delay="0.1s">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <div className="d-flex align-items-center justify-content-center bg-light" style={{ width: '60px', height: '60px' }}>
                                    <FontAwesomeIcon icon={faUserCheck} className="fa-2x text-primary" />
                                </div>
                                <h1 className="display-1 text-light mb-0">01</h1>
                            </div>
                            <h5>Özgün Tasarımlar</h5>
                        </Col>
                        <Col md={6} lg={3} className="wow fadeIn" data-wow-delay="0.3s">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <div className="d-flex align-items-center justify-content-center bg-light" style={{ width: '60px', height: '60px' }}>
                                    <FontAwesomeIcon icon={faCheck} className="fa-2x text-primary" />
                                </div>
                                <h1 className="display-1 text-light mb-0">02</h1>
                            </div>
                            <h5>Kaliteli Malzeme</h5>
                        </Col>
                        <Col md={6} lg={3} className="wow fadeIn" data-wow-delay="0.5s">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <div className="d-flex align-items-center justify-content-center bg-light" style={{ width: '60px', height: '60px' }}>
                                    <FontAwesomeIcon icon={faDraftingCompass} className="fa-2x text-primary" />
                                </div>
                                <h1 className="display-1 text-light mb-0">03</h1>
                            </div>
                            <h5>Ücretsiz Danışmanlık</h5>
                        </Col>
                        <Col md={6} lg={3} className="wow fadeIn" data-wow-delay="0.7s">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <div className="d-flex align-items-center justify-content-center bg-light" style={{ width: '60px', height: '60px' }}>
                                    <FontAwesomeIcon icon={faHeadphones} className="fa-2x text-primary" />
                                </div>
                                <h1 className="display-1 text-light mb-0">04</h1>
                            </div>
                            <h5>Müşteri Memnuniyeti</h5>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
                <Container className="about px-lg-0">
                    <Row className="g-0 mx-lg-0">
                        <Col lg={6} className="ps-lg-0" style={{ minHeight: '400px' }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute img-fluid w-100 h-100" src="/about.jpg" style={{ objectFit: 'fill' }} alt="" />
                            </div>
                        </Col>
                        <Col lg={6} className="about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                            <div className="p-lg-5 pe-lg-0">
                                <div className="section-title text-start">
                                    <h1 className="display-5 mb-4">Hakkımızda</h1>
                                </div>
                                <p className="mb-4 pb-2">Koç Yapı İnş. olarak önceliğimiz her zaman müşteri memnuniyeti olup müşterilerimizin talepleri ve özel istekleri doğrultusunda tüm süreci birlikte yürütüp onlara sadece bir ev değil güvenle yaşayacakları uzun ömürlü bir yuva verme misyonuyla çıktığımız bu yolda alnı ak bir şekilde hizmetlerimize devam etmekteyiz. Bir telefon kadar yakınız. </p>
                                <Row className="g-4 mb-4 pb-2">
                                    <Col sm={6} className="wow fadeIn" data-wow-delay="0.1s">
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{ width: '60px', height: '60px' }}>
                                                <i className="fa fa-users fa-2x text-primary"></i>
                                            </div>
                                            <div className="ms-3">
                                                <h2 className="text-primary mb-1" data-toggle="counter-up">53</h2>
                                                <p className="fw-medium mb-0">Mutlu Müşteriler</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} className="wow fadeIn" data-wow-delay="0.3s">
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{ width: '60px', height: '60px' }}>
                                                <i className="fa fa-check fa-2x text-primary"></i>
                                            </div>
                                            <div className="ms-3">
                                                <h2 className="text-primary mb-1" data-toggle="counter-up">53</h2>
                                                <p className="fw-medium mb-0">Tamamlanmış Projeler</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Button href="https://wa.me/905325579054" className="btn-primary py-3 px-5">Whatsapp İletişim</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>



            <div className="container-fluid bg-dark text-light footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
                <Container>
                    <Row className="g-5 pb-5">
                        <Col lg={3} md={6}>
                            <h4 className="text-light mb-4">Adres</h4>
                            <p className="mb-2"><FontAwesomeIcon icon={faLocationDot} className="mx-3" />Köprübaşı Mah. Aralık Cad. No:74/1 Serdivan</p>
                            <p className="mb-2"><FontAwesomeIcon icon={faPhone} className="mx-3" />0 532 557 9054</p>
                            <p className="mb-2"><FontAwesomeIcon icon={faEnvelope} className="mx-3" />info@kocyapi.com</p>
                            <div className="d-flex pt-2">
                                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faInstagram} className="mx-3" /></Button>
                                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faFacebookF} className="mx-3" /></Button>
                                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faTwitter} className="mx-3" /></Button>
                                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faYoutube} className="mx-3" /></Button>
                            </div>
                        </Col>
                        {/* Remaining columns */}
                    </Row>
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; <a className="border-bottom" href="#">@kocyapi</a>, All Right Reserved.
                            </div>
                            <div className="col-md-6 text-center text-md-end">
                                Designed By <a className="border-bottom" href="https://htmlcodex.com">Koç Yapı</a>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default AboutPage