import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import {  faCheck, faChevronLeft, faChevronRight, faClock, faDraftingCompass, faEnvelope, faEye, faHeadphones,  faLink,  faLocationDot,  faMapMarkerAlt, faPhone, faPhoneAlt,  faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Button, Carousel, Col, Container, Form, Nav, Navbar, Row } from 'react-bootstrap'

function HomePage() {

    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? testimonials.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const goToNext = () => {
        const isLastSlide = currentIndex === testimonials.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    return (
        <div>
            <Container fluid className="bg-light p-0">
                <Row className="gx-0 d-none d-lg-flex">
                    <Col lg={7} className="px-5 text-start">
                        <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="text-primary me-2" />
                            <small>Köprübaşı Mah. Aralık Cad. No:74/1 Serdivan</small>
                        </div>
                        <div className="h-100 d-inline-flex align-items-center py-3">
                            <FontAwesomeIcon icon={faClock} className="text-primary me-2" />
                            <small>P.tesi - Cuma : 09.00 - 18.00</small>
                        </div>
                    </Col>
                    <Col lg={5} className="px-5 text-end">
                        <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                            <FontAwesomeIcon icon={faPhoneAlt} className="text-primary me-2" />
                            <small>0 532 557 9054</small>
                        </div>
                        <div className="h-100 d-inline-flex align-items-center">
                            <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faFacebookF} /></a>
                            <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faTwitter} /></a>
                            <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                            <a className="btn btn-sm-square bg-white text-primary me-0" href="/"><FontAwesomeIcon icon={faInstagram} /></a>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Navbar bg="white" expand="lg" sticky="top" className="p-0">
                <Navbar.Brand href="index.html" className="d-flex align-items-center px-4 px-lg-5">
                    <h2 className="m-0 text-primary">KOÇ YAPI İNŞAAT</h2>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarCollapse" className="me-4" />
                <Navbar.Collapse id="navbarCollapse">
                    <Nav className="ms-auto p-4 p-lg-0">
                        <Nav.Link href="/" className="nav-item nav-link active">AnaSayfa</Nav.Link>
                        <Nav.Link href="/about" className="nav-item nav-link">Hakkımızda</Nav.Link>
                        <Nav.Link href="/services" className="nav-item nav-link">Hizmetlerimiz</Nav.Link>
                        <Nav.Link href="/portfolio" className="nav-item nav-link">Projeler</Nav.Link>
                        {/* <NavDropdown title="Pages" id="basic-nav-dropdown">
                            <NavDropdown.Item href="feature.html">Feature</NavDropdown.Item>
                            <NavDropdown.Item href="quote.html">Free Quote</NavDropdown.Item>
                            <NavDropdown.Item href="team.html">Our Team</NavDropdown.Item>
                            <NavDropdown.Item href="testimonial.html">Testimonial</NavDropdown.Item>
                            <NavDropdown.Item href="404.html">404 Page</NavDropdown.Item>
                        </NavDropdown> */}
                        <Nav.Link href="/contact" className="nav-item nav-link">İletişim</Nav.Link>
                    </Nav>
                    <Button href="https://wa.me/905325579054" className="btn btn-primary py-4 px-lg-5 d-none d-lg-block">Teklif Al<i className="fa fa-arrow-right ms-3"></i></Button>
                </Navbar.Collapse>
            </Navbar>
            <Container fluid className="p-0 pb-5">
                <Carousel className="header-carousel position-relative">
                    <Carousel.Item className="position-relative">
                        <img className="d-block w-100" src="/slider-1.jpg" alt="" />
                        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{ background: 'rgba(53, 53, 53, .7)' }}>
                            <Container>
                                <Row className="justify-content-center">
                                    <Col xs={12} lg={8} className="text-center">
                                        <h5 className="text-white text-uppercase mb-3 animated slideInDown">Koç Yapı İnş.</h5>
                                        <h1 className="display-3 text-white animated slideInDown mb-4">Çelik Yapı Evler</h1>
                                        <p className="fs-5 fw-medium text-white mb-4 pb-2">Depreme dayanıklı hayalinizde ki villanıza kavuşabilirsinzi. Anahtar teslim projelerimiz sizlerle</p>
                                        {/* <Button variant="primary" className="py-md-3 px-md-5 me-3 animated slideInLeft">Read More</Button>
                                        <Button variant="light" className="py-md-3 px-md-5 animated slideInRight">Free Quote</Button> */}
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="position-relative">
                        <img className="d-block w-100" src="/slider-2.jpg" alt="" />
                        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{ background: 'rgba(53, 53, 53, .7)' }}>
                            <Container>
                                <Row className="justify-content-center">
                                    <Col xs={12} lg={8} className="text-center">
                                        <h5 className="text-white text-uppercase mb-3 animated slideInDown">Koç Yapı İnş.</h5>
                                        <h1 className="display-3 text-white animated slideInDown mb-4">Çelik Bahçeli Ev</h1>
                                        <p className="fs-5 fw-medium text-white mb-4 pb-2">Hayalinizde ki bahçeli eviniz için iletişime geçebilirsiniz. Tamamen çelik ve talepleriniz doğrultusunda projelendirilme.</p>
                                        {/* <Button variant="primary" className="py-md-3 px-md-5 me-3 animated slideInLeft">Read More</Button>
                                        <Button variant="light" className="py-md-3 px-md-5 animated slideInRight">Free Quote</Button> */}
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="position-relative">
                        <img className="d-block w-100" src="/slider-3.jpg" alt="" />
                        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{ background: 'rgba(53, 53, 53, .7)' }}>
                            <Container>
                                <Row className="justify-content-center">
                                    <Col xs={12} lg={8} className="text-center">
                                        <h5 className="text-white text-uppercase mb-3 animated slideInDown">Koç Yapı İnş.</h5>
                                        <h1 className="display-3 text-white animated slideInDown mb-4">Tiny House</h1>
                                        <p className="fs-5 fw-medium text-white mb-4 pb-2">Tiny House hizmetimiz ile sizlerin hizmetinizdeyiz.</p>
                                        {/* <Button variant="primary" className="py-md-3 px-md-5 me-3 animated slideInLeft">Read More</Button>
                                        <Button variant="light" className="py-md-3 px-md-5 animated slideInRight">Free Quote</Button> */}
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </Container>

            <div className="container-xxl py-5">
                <Container>
                    <Row className="g-5">
                        <Col md={6} lg={3} className="wow fadeIn" data-wow-delay="0.1s">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <div className="d-flex align-items-center justify-content-center bg-light" style={{ width: '60px', height: '60px' }}>
                                    <FontAwesomeIcon icon={faUserCheck} className="fa-2x text-primary" />
                                </div>
                                <h1 className="display-1 text-light mb-0">01</h1>
                            </div>
                            <h5>Özgün Tasarımlar</h5>
                        </Col>
                        <Col md={6} lg={3} className="wow fadeIn" data-wow-delay="0.3s">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <div className="d-flex align-items-center justify-content-center bg-light" style={{ width: '60px', height: '60px' }}>
                                    <FontAwesomeIcon icon={faCheck} className="fa-2x text-primary" />
                                </div>
                                <h1 className="display-1 text-light mb-0">02</h1>
                            </div>
                            <h5>Kaliteli Malzeme</h5>
                        </Col>
                        <Col md={6} lg={3} className="wow fadeIn" data-wow-delay="0.5s">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <div className="d-flex align-items-center justify-content-center bg-light" style={{ width: '60px', height: '60px' }}>
                                    <FontAwesomeIcon icon={faDraftingCompass} className="fa-2x text-primary" />
                                </div>
                                <h1 className="display-1 text-light mb-0">03</h1>
                            </div>
                            <h5>Ücretsiz Danışmanlık</h5>
                        </Col>
                        <Col md={6} lg={3} className="wow fadeIn" data-wow-delay="0.7s">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <div className="d-flex align-items-center justify-content-center bg-light" style={{ width: '60px', height: '60px' }}>
                                    <FontAwesomeIcon icon={faHeadphones} className="fa-2x text-primary" />
                                </div>
                                <h1 className="display-1 text-light mb-0">04</h1>
                            </div>
                            <h5>Müşteri Memnuniyeti</h5>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
                <Container className="about px-lg-0">
                    <Row className="g-0 mx-lg-0">
                        <Col lg={6} className="ps-lg-0" style={{ minHeight: '400px' }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute img-fluid w-100 h-100" src="/about.jpg" style={{ objectFit: 'fill' }} alt="" />
                            </div>
                        </Col>
                        <Col lg={6} className="about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                            <div className="p-lg-5 pe-lg-0">
                                <div className="section-title text-start">
                                    <h1 className="display-5 mb-4">Hakkımızda</h1>
                                </div>
                                <p className="mb-4 pb-2">Koç Yapı İnş. olarak önceliğimiz her zaman müşteri memnuniyeti olup müşterilerimizin talepleri ve özel istekleri doğrultusunda tüm süreci birlikte yürütüp onlara sadece bir ev değil güvenle yaşayacakları uzun ömürlü bir yuva verme misyonuyla çıktığımız bu yolda alnı ak bir şekilde hizmetlerimize devam etmekteyiz. Bir telefon kadar yakınız. </p>
                                <Row className="g-4 mb-4 pb-2">
                                    <Col sm={6} className="wow fadeIn" data-wow-delay="0.1s">
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{ width: '60px', height: '60px' }}>
                                                <i className="fa fa-users fa-2x text-primary"></i>
                                            </div>
                                            <div className="ms-3">
                                                <h2 className="text-primary mb-1" data-toggle="counter-up">53</h2>
                                                <p className="fw-medium mb-0">Mutlu Müşteriler</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} className="wow fadeIn" data-wow-delay="0.3s">
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{ width: '60px', height: '60px' }}>
                                                <i className="fa fa-check fa-2x text-primary"></i>
                                            </div>
                                            <div className="ms-3">
                                                <h2 className="text-primary mb-1" data-toggle="counter-up">53</h2>
                                                <p className="fw-medium mb-0">Tamamlanmış Projeler</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Button href="https://wa.me/905325579054" className="btn-primary py-3 px-5">Whatsapp İletişim</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


            <div className="container-xxl py-5">
                <Container>
                    <div className="section-title text-center">
                        <h1 className="display-5 mb-5">Hizmetlerimiz</h1>
                    </div>
                    <Row className="g-4">
                        <Col md={6} lg={4} className="wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src="/service-1.jpg" alt="" />
                                </div>
                                <div className="p-4 text-center border border-5 border-light border-top-0">
                                    <h4 className="mb-3">Çelik Ev</h4>
                                    <p>Güvenli ve şık tasarımıyla çelik evlerimizde sizlere sıcak yuvalar kuruyoruz.</p>
                                    <Button href='https://wa.me/905325579054' variant="primary" className="fw-medium">İletişim<i className="fa fa-arrow-right ms-2"></i></Button>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4} className="wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src="/service-2.jpg" alt="" />
                                </div>
                                <div className="p-4 text-center border border-5 border-light border-top-0">
                                    <h4 className="mb-3">Çelik Villa</h4>
                                    <p>Kaliteli malzeme kullanımı ve sizin talepleriniz doğrultusunda şık tasarım villalarımız.</p>
                                    <Button href='https://wa.me/905325579054' variant="primary" className="fw-medium">İletişim<i className="fa fa-arrow-right ms-2"></i></Button>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4} className="wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src="/service-3.jpg" alt="" />
                                </div>
                                <div className="p-4 text-center border border-5 border-light border-top-0">
                                    <h4 className="mb-3">Tiny House</h4>
                                    <p>Gösterişli tasarımlarla sizlere Ev konseptinde Tiny House lar sunuyoruz .</p>
                                    <Button href='https://wa.me/905325579054' variant="primary" className="fw-medium">İletişim<i className="fa fa-arrow-right ms-2"></i></Button>
                                </div>
                            </div>
                        </Col>
                        {/* <Col md={6} lg={4} className="wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src="/service-4.jpg" alt="" />
                                </div>
                                <div className="p-4 text-center border border-5 border-light border-top-0">
                                    <h4 className="mb-3">Wooden Floor</h4>
                                    <p>Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem diam.</p>
                                    <Button variant="primary" className="fw-medium">Read More<i className="fa fa-arrow-right ms-2"></i></Button>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4} className="wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src="/service-5.jpg" alt="" />
                                </div>
                                <div className="p-4 text-center border border-5 border-light border-top-0">
                                    <h4 className="mb-3">Wooden Furniture</h4>
                                    <p>Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem diam.</p>
                                    <Button variant="primary" className="fw-medium">Read More<i className="fa fa-arrow-right ms-2"></i></Button>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} lg={4} className="wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item">
                                <div className="overflow-hidden">
                                    <img className="img-fluid" src="/service-6.jpg" alt="" />
                                </div>
                                <div className="p-4 text-center border border-5 border-light border-top-0">
                                    <h4 className="mb-3">Custom Work</h4>
                                    <p>Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam lorem diam.</p>
                                    <Button variant="primary" className="fw-medium">Read More<i className="fa fa-arrow-right ms-2"></i></Button>
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                </Container>
            </div>
            <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
                <Container className="feature px-lg-0">
                    <Row className="g-0 mx-lg-0">
                        <Col lg={6} className="feature-text py-5 ps-lg-5 ps-4 wow fadeIn" data-wow-delay="0.5s">
                            <div className="p-lg-5">
                                <div className="section-title text-start">
                                    <h1 className="display-5 mb-4">Neden Biz?</h1>
                                </div>
                                <p className="mb-4 pb-2">Hizmet kalitemizden ödün vermeden müşterilerilemizle birlikte tüm süreci yöneterek anahtar teslim projelerimizle yanınızdayız.</p>
                                <div className="row g-4">
                                    <Col xs={6}>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{ width: '60px', height: '60px' }}>
                                                <FontAwesomeIcon icon={faCheck} className="fa-2x text-primary" />
                                            </div>
                                            <div className="ms-4">
                                                <p className="mb-2">Kaliteli</p>
                                                <h5 className="mb-0">Hizmet</h5>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{ width: '60px', height: '60px' }}>
                                                <FontAwesomeIcon icon={faUserCheck} className="fa-2x text-primary" />
                                            </div>
                                            <div className="ms-4">
                                                <p className="mb-2">Özgün</p>
                                                <h5 className="mb-0">Tasarımlar</h5>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{ width: '60px', height: '60px' }}>
                                                <FontAwesomeIcon icon={faDraftingCompass} className="fa-2x text-primary" />
                                            </div>
                                            <div className="ms-4">
                                                <p className="mb-2">Ücretsiz</p>
                                                <h5 className="mb-0">Danışmanlık</h5>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{ width: '60px', height: '60px' }}>
                                                <FontAwesomeIcon icon={faHeadphones} className="fa-2x text-primary" />
                                            </div>
                                            <div className="ms-4">
                                                <p className="mb-2">Müşteri</p>
                                                <h5 className="mb-0">Memnuniyeti</h5>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} className="pe-lg-0" style={{ minHeight: '400px' }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute img-fluid w-100 h-100" src="/feature.jpg" style={{ objectFit: 'cover' }} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="py-5">
                <Container>
                    <div className="text-center mb-5">
                        <h1 className="display-5 mb-5">Projeler</h1>
                    </div>
                    <Row className="g-4">
                        <Col lg={4} md={6} className="portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
                            <div className="rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid w-100" src="/project-1.jpeg" alt="" />
                                    <div className="portfolio-overlay">
                                        <Button variant="outline-light" className="btn-square mx-1" href="/" data-lightbox="portfolio">
                                            <FontAwesomeIcon icon={faEye} />
                                        </Button>
                                        <Button variant="outline-light" className="btn-square mx-1" href="/">
                                            <FontAwesomeIcon icon={faLink} />
                                        </Button>
                                    </div>
                                </div>
                                <div className="border border-5 border-light border-top-0 p-4">
                                    <p className="text-primary fw-medium mb-2">Çelik Villa</p>
                                    <h5 className="lh-base mb-0">200 m2 Çelik Villa Projemiz</h5>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
                            <div className="rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid w-100" src="/project-2.jpeg" alt="" />
                                    <div className="portfolio-overlay">
                                        <Button variant="outline-light" className="btn-square mx-1" href="/" data-lightbox="portfolio">
                                            <FontAwesomeIcon icon={faEye} />
                                        </Button>
                                        <Button variant="outline-light" className="btn-square mx-1" href="/">
                                            <FontAwesomeIcon icon={faLink} />
                                        </Button>
                                    </div>
                                </div>
                                <div className="border border-5 border-light border-top-0 p-4">
                                    <p className="text-primary fw-medium mb-2">Çelik Villa</p>
                                    <h5 className="lh-base mb-0">Teraslı Çelik Villa Projemiz</h5>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
                            <div className="rounded overflow-hidden">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid w-100" src="/project-3.jpeg" alt="" />
                                    <div className="portfolio-overlay">
                                        <Button variant="outline-light" className="btn-square mx-1" href="/" data-lightbox="portfolio">
                                            <FontAwesomeIcon icon={faEye} />
                                        </Button>
                                        <Button variant="outline-light" className="btn-square mx-1" href="/">
                                            <FontAwesomeIcon icon={faLink} />
                                        </Button>
                                    </div>
                                </div>
                                <div className="border border-5 border-light border-top-0 p-4">
                                    <p className="text-primary fw-medium mb-2">Tiny House</p>
                                    <h5 className="lh-base mb-0">Tiny House Projemiz</h5>
                                </div>
                            </div>
                        </Col>
                        {/* Diğer portföy öğeleri buraya eklenecek */}
                    </Row>
                </Container>
            </div>

            <div className="bg-light overflow-hidden my-5">
                <Container className="quote">
                    <Row className="g-0">
                        <Col lg={6} className="ps-lg-0" style={{ minHeight: '400px' }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute img-fluid w-100 h-100" src="/quote.jpg" style={{ objectFit: 'cover' }} alt="" />
                            </div>
                        </Col>
                        <Col lg={6} className="quote-text py-5 wow fadeIn" data-wow-delay="0.5s">
                            <div className="p-lg-5 pe-lg-0">
                                <div className="section-title text-start">
                                    <h1 className="display-5 mb-4">İletişim</h1>
                                </div>
                                <p className="mb-4 pb-2">Bizlerle ister mail üzerinde isterseniz telefon numaralarımızdan isterseniz de whatsapp üzerinden en hızlıca iletişim kurabilirsiniz.</p>
                                <Form >
                                    <Row className="g-3">
                                        <Col xs={12} sm={6}>
                                            <Form.Control type="text" placeholder="İsim" style={{ height: '55px' }} />
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Control type="email" placeholder="Mail" style={{ height: '55px' }} />
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Control type="text" placeholder="telefon" style={{ height: '55px' }} />
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Select style={{ height: '55px' }}>
                                                <option selected>Hizmet</option>
                                                <option value="1">Çelik Ev</option>
                                                <option value="2">Çelik Villa</option>
                                                <option value="3">Tiny House</option>
                                            </Form.Select>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Control as="textarea" placeholder="Not" />
                                        </Col>
                                        <Col xs={12}>
                                            <Button variant="primary" className="w-100 py-3" type="submit">Gönder</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* <div className="container-xxl py-5">
                <Container>
                    <div className="section-title text-center">
                        <h1 className="display-5 mb-5">Team Members</h1>
                    </div>
                    <Row className="g-4">
                        {teamMembers.map((member, index) => (
                            <Col lg={3} md={6} key={index} className="wow fadeInUp" data-wow-delay={`0.${index + 1}s`}>
                                <div className="team-item">
                                    <div className="overflow-hidden position-relative">
                                        <img className="img-fluid" src={`/team-${index + 1}.jpg`} alt="" />
                                        <div className="team-social">
                                            <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faFacebookF} /></a>
                                            <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faTwitter} /></a>
                                            <a className="btn btn-sm-square bg-white text-primary me-1" href="/"><FontAwesomeIcon icon={faInstagram} /></a>
                                        </div>
                                    </div>
                                    <div className="text-center border border-5 border-light border-top-0 p-4">
                                        <h5 className="mb-0">{member.name}</h5>
                                        <small>{member.designation}</small>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div> */}

            <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
                <Container>
                    <div className="section-title text-center">
                        <h1 className="display-5 mb-5">Mutlu Müşteriler</h1>
                    </div>
                    <div className="testimonial-slider">
                        <div className="img-container">
                            <div className="testimonial-item text-center ">
                                <img className="img-fluid bg-light p-2 mx-auto mb-3" src={`/testimonial-${currentIndex + 1}.jpg`} style={{ width: '90px', height: '90px' }} alt="Testimonial" />
                                <button className="arrow left-arrow" onClick={goToPrevious}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>
                                <button className="arrow right-arrow" onClick={goToNext}>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </div>
                            <div className="testimonial-text text-center p-4">
                                <p>{testimonials[currentIndex].text}</p>
                                <h5 className="mb-1">{testimonials[currentIndex].client}</h5>
                                <span className="fst-italic">{testimonials[currentIndex].profession}</span>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>

            <div className="container-fluid bg-dark text-light footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
                <Container>
                    <Row className="g-5 pb-5">
                        <Col lg={3} md={6}>
                            <h4 className="text-light mb-4">Adres</h4>
                            <p className="mb-2"><FontAwesomeIcon icon={faLocationDot} className="mx-3"/>Köprübaşı Mah. Aralık Cad. No:74/1 Serdivan</p>
                            <p className="mb-2"><FontAwesomeIcon icon={faPhone} className="mx-3"/>0 532 557 9054</p>
                            <p className="mb-2"><FontAwesomeIcon icon={faEnvelope} className="mx-3"/>info@kocyapi.com</p>
                            <div className="d-flex pt-2">
                                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faInstagram} className="mx-3"/></Button>
                                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faFacebookF} className="mx-3"/></Button>
                                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faTwitter} className="mx-3"/></Button>
                                <Button className="btn btn-outline-light btn-social" href=""><FontAwesomeIcon icon={faYoutube} className="mx-3"/></Button>
                            </div>
                        </Col>
                        {/* Remaining columns */}
                    </Row>
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; <a className="border-bottom" href="#">@kocyapi</a>, All Right Reserved.
                            </div>
                            <div className="col-md-6 text-center text-md-end">
                                Designed By <a className="border-bottom" href="https://htmlcodex.com">Koç Yapı</a>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>

        </div>

    )
}

// const teamMembers = [
//     { name: 'Full Name', designation: 'Designation' },
//     { name: 'Full Name', designation: 'Designation' },
//     { name: 'Full Name', designation: 'Designation' },
//     { name: 'Full Name', designation: 'Designation' }
// ];

const testimonials = [
    { text: 'Koç Yapı inşaatın başlangıcından sonuna kadar her işin arkasında duran ve tüm sorunları karşılıklı olarak konuşup çözüme kavuşturabilieceğiniz harika bir firma. Ev yapmanın zorluklarının üstesinden dinamik ve kaliteli kadrosuyla gelebilmektedir. Siz sadece arkanıza yaslanın ve anın tadını çıkarın. Onlar sizin yerinize herşeyi düşünüyor.', client: 'Melih GÖKDENİZ', profession: 'Müşteri' },
    { text: 'Öncelikle Koç Yapı ya sonsuz teşekkürler. Yaptıkları her işi mükemmel yapabilme gibi bir özellikleri var. Tüm sorularımıza cevap verip bizi dikkatle dinlemeleri bu zamanda zor bulunan bir durum. Onlara tekrar tekrar teşekkür ediyorum.', client: 'Yücel DEMİRÖZ', profession: 'Müşteri' },
    { text: 'Ev yapma sürecini daha önce tecribe edenler bir ev yapmanın ne kadar zor olduğunu bilirler. Ama bu durum Koç Yapı ile tanışınca son buluyor. Gerçekten işlerini düzgün yapan kaliteli bir kadroları var. Üstüne üstlük sizlerle iletişim kurup sorunlarınız çözebiliyorlar. Çok teşekkür ederiz.', client: 'Buse YILMAZER TUNCA', profession: 'Müşteri' }
];

export default HomePage