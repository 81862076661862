
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutPage from './About';
import ContactPage from './Contact';
import HomePage from './Home';
import PortfolioPage from './Portfolio';
import ServicesPage from './Services';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes >
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/contact" element={<ContactPage />} />

          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
